import * as React from 'react';

import {
    BooleanField, Datagrid, DateField, DateInput, List, NullableBooleanInput,
    NumberField, SearchInput, Link, useRecordContext, FieldProps, TextField,
    DeleteButton, EditButton, ReferenceField
} from 'react-admin';

import { useMediaQuery, Theme, SxProps, Typography } from '@mui/material';


import { IProfileUser } from './types';


interface Props extends FieldProps<IProfileUser> {
    size?: string;
    sx?: SxProps;
}



const userFilters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="lastSeen_gte" />,
    <NullableBooleanInput source="has_ordered" />,
    <NullableBooleanInput source="has_newsletter" defaultValue />,
];

const DaysMonths = (props: Props) => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const { size } = props;
    const record = useRecordContext<IProfileUser>();


    return (
        <List
            filters={isSmall ? userFilters : undefined}
            sort={{ field: 'lastSeen', order: 'DESC' }}
            perPage={25}
        // aside={<UserListAside />}
        >

            <Datagrid
                optimized
                rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >

                <NumberField source='id' label="resources.stats.months.fields.id" />
                
                <NumberField source='profileUserId' label="resources.stats.months.fields.profileUserId" />

                <DateField source='date' label="resources.stats.months.fields.date" />

                <TextField source='sumBeginUsdt' label="resources.stats.months.fields.sumBeginUsdt" />
                <TextField source='sumLastUsdt' label="resources.stats.months.fields.sumLastUsdt" />
                <TextField source='sumChangeUsdt' label="resources.stats.months.fields.sumChangeUsdt" />
                <TextField source='sumChangePcnt' label="resources.stats.months.fields.sumChangePcnt" />

                <DateField source='createdAt' label="resources.stats.months.fields.createdAt" />
                <DateField source='updatedAt' label="resources.stats.months.fields.updatedAt" />
                

                

                {/* <EditButton /> */}
                {/* <DeleteButton /> */}

            </Datagrid>

        </List>
    );
};

export default DaysMonths;
