import * as React from 'react';

import {
    DateInput,
    Edit,
    NullableBooleanInput,
    TextInput,
    PasswordInput,
    SimpleForm,
    useTranslate,
    BooleanInput,
    ReferenceInput,
    SelectInput,
} from 'react-admin';

import { Grid, Box, Typography } from '@mui/material';

import { validateForm } from './AccountCreate';



// const AccountTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;

const tariffPlanField = (record: any) => ` #${record.name}`;



const AccountEdit = () => (

    <Edit>
        <SimpleForm
            sx={{ maxWidth: 500 }}
            defaultValues={{
                active: true,
            }}
            validate={validateForm}
        >
            <Separator />
            <SectionTitle label="resources.billing.account.fieldGroups.main" />

            <ReferenceInput source="profileUserId" reference="profile/users">
                <SelectInput optionText="username" isRequired fullWidth />
            </ReferenceInput>

            <ReferenceInput source="tariffPlanId" reference="tariff/plans">
                <SelectInput optionText={tariffPlanField} isRequired fullWidth />
            </ReferenceInput>

            <TextInput source='tariffPlanId' fullWidth />
                <TextInput source='currentTariffStageId' fullWidth />
                <TextInput source='currentTariffStageNumber' fullWidth />
                <TextInput source='tariffStagesCount' fullWidth />
                <TextInput source='currentBillingStageId' fullWidth />
                <TextInput source='currentDayId' fullWidth />
                <TextInput source='currentDayNumber' fullWidth />
                <TextInput source='tradingDaysCount' fullWidth />
                <TextInput source='totalLast' fullWidth />
                <TextInput source='totalAccountBegin' fullWidth />
                <TextInput source='totalAccountChange' fullWidth />
                <TextInput source='totalAccountPcnt' fullWidth />
                <BooleanInput source='selected' fullWidth />
                <BooleanInput source='activeManual' fullWidth />
                <BooleanInput source='activeForever' fullWidth />
                <TextInput source='beginAt' fullWidth />
                <TextInput source='endAt' fullWidth />
                <TextInput source='stopAt' fullWidth />
                <TextInput source='stopReason' fullWidth />
                <TextInput source='complete' fullWidth />
            <Separator />
            <SectionTitle label="resources.billing.account.fieldGroups.status" />

            {/* <BooleanInput source="active" fullWidth /> */}

        </SimpleForm>

    </Edit>
);


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default AccountEdit;
