import * as React from 'react';
import {
    Create, DateInput, SimpleForm, TextInput, useTranslate, PasswordInput,
    email, BooleanInput,
} from 'react-admin';
import { Box, Typography } from '@mui/material';


export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.name) {
        errors.name = 'ra.validation.required';
    }
    return errors;
};


const RoleCreate = () => (
    <Create>
        <SimpleForm
            sx={{ maxWidth: 500 }}
            // Here for the GQL provider
            defaultValues={{
                administrator: false,
                moderator: true,
                active: true,
            }}
            validate={validateForm}
        >
            <SectionTitle label="resources.profile.roles.fieldGroups.main" />

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput source="name" isRequired fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput source="description" fullWidth />
                </Box>
            </Box>

            <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <BooleanInput source="administrator" fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <BooleanInput source="moderator" fullWidth />
                </Box>
                
            </Box>


            <Separator />
            <SectionTitle label="resources.profile.roles.fieldGroups.status" />

            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <BooleanInput source="active" fullWidth />
            </Box>

        </SimpleForm>
    </Create>
);


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default RoleCreate;
