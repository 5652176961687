import WithdrawIcon from '@mui/icons-material/People';

import WithdrawList from './WithdrawList';
import WithdrawCreate from './WithdrawCreate';
import WithdrawEdit from './WithdrawEdit';

const resource = {
    list: WithdrawList,
    create: WithdrawCreate,
    edit: WithdrawEdit,
    icon: WithdrawIcon,
};

export default resource;
