import AccountIcon from '@mui/icons-material/People';

import StagesList from './StagesList';
// import AccountCreate from './AccountCreate';
// import AccountEdit from './AccountEdit';

const resource = {
    list: StagesList,
    // create: AccountCreate,
    // edit: AccountEdit,
    icon: AccountIcon,
};

export default resource;
