import * as React from 'react';

import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    EditButton,
    FunctionField,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    TextField
} from 'react-admin';

import { useMediaQuery, Theme } from '@mui/material';



const filters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="last_seen_gte" />,
    <NullableBooleanInput source="has_ordered" />,

];


const tariffPlanField = (record: any) => `#id${record.id} #${record.name}`;



const AccountList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            filters={filters}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={25}
        >

            <Datagrid
                optimized
                // rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                <TextField source="id" label="id" />
                <ReferenceField source="profileUserId" reference="profile/users" label="resources.billing.account.fields.user">
                    <TextField source="username" />
                </ReferenceField>

                <ReferenceField source="tariffPlanId" reference="tariff/plans" label="resources.billing.account.fields.tariff">
                    <FunctionField render={tariffPlanField} />
                </ReferenceField>   
                <NumberField source='tariffPlanId' label="resources.billing.account.fields.tariffPlanId" />
                <NumberField source='currentTariffStageId' label="resources.billing.account.fields.currentTariffStageId" />
                <NumberField source='currentTariffStageNumber' label="resources.billing.account.fields.currentTariffStageNumber" />
                <NumberField source='tariffStagesCount' label="resources.billing.account.fields.tariffStagesCount" />
                <NumberField source='currentBillingStageId' label="resources.billing.account.fields.currentBillingStageId" />
                <NumberField source='currentDayId' label="resources.billing.account.fields.currentDayId" />
                <NumberField source='currentDayNumber' label="resources.billing.account.fields.currentDayNumber" />
                <NumberField source='tradingDaysCount' label="resources.billing.account.fields.tradingDaysCount" />
                <NumberField source='totalLast' label="resources.billing.account.fields.totalLast" />
                <NumberField source='totalAccountBegin' label="resources.billing.account.fields.totalAccountBegin" />
                <NumberField source='totalAccountChange' label="resources.billing.account.fields.totalAccountChange" />
                <NumberField source='totalAccountPcnt' label="resources.billing.account.fields.totalAccountPcnt" />
                <BooleanField source='selected' label="resources.billing.account.fields.selected" />
                <BooleanField source='activeManual' label="resources.billing.account.fields.activeManual" />
                <BooleanField source='activeForever' label="resources.billing.account.fields.activeForever" />
                <NumberField source='beginAt' label="resources.billing.account.fields.beginAt" />
                <NumberField source='endAt' label="resources.billing.account.fields.endAt" />
                <NumberField source='stopAt' label="resources.billing.account.fields.stopAt" />
                <NumberField source='stopReason' label="resources.billing.account.fields.stopReason" />
                <NumberField source='complete' label="resources.billing.account.fields.complete" />
                {/* <NumberField source='tariff_plans.realCostUsdt' label="resources.billing.account.fields.realCostUsdt" />
                <NumberField source='tariff_plans.startBalanceUsdt' label="resources.billing.account.fields.startBalance" />
                <NumberField source='tariff_plans.leverage' label="resources.billing.account.fields.leverage" />
                <NumberField source='tariff_plans.totalLast' label="resources.billing.account.fields.leverage" />
                <NumberField source='tariff_plans.totalAccountBegin' label="resources.billing.account.fields.leverage" />
                <NumberField source='tariff_plans.totalAccountChange' label="resources.billing.account.fields.leverage" />
                <NumberField source='tariff_plans.totalAccountPcnt' label="resources.billing.account.fields.leverage" /> */}

                {/* <BooleanField source="active" label="resources.billing.account.fields.active" /> */}
                <DateField source="createdAt" showTime label="resources.billing.account.fields.createdAt" />
                <DateField source="updatedAt" showTime label="resources.billing.account.fields.updatedAt" />

                <EditButton />
                <DeleteButton />

            </Datagrid>

        </List>
    );
};

export default AccountList;
