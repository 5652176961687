import * as React from 'react';

import {
    Admin,
    CustomRoutes,
    Resource,
    localStorageStore,
    useStore,
    StoreContextProvider,
} from 'react-admin';

import polyglotI18nProvider from 'ra-i18n-polyglot';
import { Route } from 'react-router';

import authProvider from './authProvider';
import { Login, Layout } from './layout';
import { Dashboard } from './pages/dashboard';
import englishMessages from './i18n/en';
import { themes, ThemeName } from './themes/themes';


//// chapters imports

// profile
import profileRole from './pages/profile/roles';
import profileUser from './pages/profile/users';

// tariff
import tariffPlan from './pages/tariff/plan';
import tariffStage from './pages/tariff/stage';

// marketdata
import marketdataCoin from './pages/marketdata/coin';
import marketdataRate from './pages/marketdata/quotes';
import marketdataLastQuotes from './pages/marketdata/lastquotes';
import marketdataPairs from './pages/marketdata/pairs';

// billing
import billingAccounts from './pages/billing/account';
import billingStages from './pages/billing/stages';
import billingDays from './pages/billing/days';
import billingWallet from './pages/billing/wallet';

// payments
import paymentsInvoicesUsdt from './pages/payments/invoices';
import paymentsMainWallet from './pages/payments/MainWallet';

// faq
import faqCategory from './pages/faq/category';
import faqEntries from './pages/faq/entries';

// helppage
import helppageCategory from './pages/helppage/category';
import helppageEntries from './pages/helppage/entries';

// mainpage
import mainpageEntry from './pages/mainpage/entry';

// news
import newsCategory from './pages/news/category';
import newsPost from './pages/news/post';

// trading
import tradingExecutions from './pages/trading/executions';
import tradingOrders from './pages/trading/orders';
import tradingPositions from './pages/trading/positions';
// import tradingPositions from './pages/trading/positions';

// 

import billingContest from './pages/billing/contest';

import products from './pages/products';
import invoices from './pages/payments/invoices';
import category1 from './pages/category';

import withdraws from './pages/withdraws';
import statsDays from './pages/stats/days';
import statsMonths from './pages/stats/months';

////
import { nestProvider } from './nestProvider'




const i18nProvider = polyglotI18nProvider(
    locale => {
        if (locale === 'ru') {
            return import('./i18n/ru').then(messages => messages.default);
        }

        // Always fallback on english
        return englishMessages;
    },
    'en',
    [
        { locale: 'en', name: 'English' },
        { locale: 'ru', name: 'Русский' },
    ]
);

const store = localStorageStore(undefined, 'ECommerce');



const App = () => {
    const [themeName] = useStore<ThemeName>('themeName', 'soft');
    const lightTheme = themes.find(theme => theme.name === themeName)?.light;
    const darkTheme = themes.find(theme => theme.name === themeName)?.dark;
    return (
        <Admin
            title=""
            // dataProvider={dataProviderFactory(
            //     process.env.REACT_APP_DATA_PROVIDER || ''
            // )}
            dataProvider={nestProvider}
            store={store}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
            lightTheme={lightTheme}
            darkTheme={darkTheme}
            defaultTheme="light"
        >

            //
            <Resource name="profile/roles/core" {...profileRole} />
            <Resource name="profile/users/core" {...profileUser} />

            //
            {/* <Resource name="tariff/category" {...tariffCategory} /> */}
            <Resource name="tariff-plans/core" {...tariffPlan} />
            {/* <Resource name="tariff-stages/core" {...tariffStage} /> */}

            //
            <Resource name="marketdata/coins" {...marketdataCoin} />
            <Resource name="marketdata/last/quotes" {...marketdataLastQuotes} />
            <Resource name="marketdata/quotes" {...marketdataRate} />
            <Resource name="marketdata/pairs" {...marketdataPairs} />

            //
            <Resource name="billing/accounts/core" {...billingAccounts} />
            <Resource name="billing/days/core" {...billingDays} />
            <Resource name="billing/wallets/core" {...billingWallet} />
        
            //
            <Resource name="payments-gateway/invoices-usdt/core" {...paymentsInvoicesUsdt} />
            <Resource name="payments-gateway/main-wallets-usdt/core" {...paymentsMainWallet} />

            //
            <Resource name="faq/categories" {...faqCategory} />
            <Resource name="faq/entries" {...faqEntries} />

            //
            <Resource name="helppage/categories" {...helppageCategory} />
            <Resource name="helppage/entries" {...helppageEntries} />

            //
            <Resource name="mainpage/entries" {...mainpageEntry} />

            //
            <Resource name="news/categories" {...newsCategory} />
            <Resource name="news/posts" {...newsPost} />
            //
            <Resource name="trading/executions" {...tradingExecutions} />
            <Resource name="trading/orders" {...tradingOrders} />
            <Resource name="trading/positions" {...tradingPositions} />
            //
            <Resource name="withdraws/core" {...withdraws} />
            //
            <Resource name="stats/days/core" {...statsDays} />
            <Resource name="stats/months/core" {...statsMonths} />
            //
            {/* <Resource
                name="commands"
                {...orders}
                options={{ label: 'Orders' }}
            />
            <Resource name="invoices" {...invoices} />
            <Resource name="products" {...products} />
            <Resource name="category1" {...category1} /> */}
        </Admin>
    );
};

const AppWrapper = () => (
    <StoreContextProvider value={store}>
        <App />
    </StoreContextProvider>
);

export default AppWrapper;