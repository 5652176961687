import * as React from 'react';

import {
    BooleanField, Datagrid, DateField, DateInput, List, NullableBooleanInput,
    NumberField, SearchInput, Link, useRecordContext, FieldProps, TextField,
    DeleteButton, EditButton, ReferenceField
} from 'react-admin';

import { useMediaQuery, Theme, SxProps, Typography } from '@mui/material';


import MobileGrid from './MobileGrid';
import UserListAside from './UserListAside';

import AvatarField from './AvatarField';
import { IProfileUser } from './types';

interface Props extends FieldProps<IProfileUser> {
    size?: string;
    sx?: SxProps;
}



const userFilters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="lastSeen_gte" />,
    <NullableBooleanInput source="has_ordered" />,
    <NullableBooleanInput source="has_newsletter" defaultValue />,
];

const UserList = (props: Props) => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const { size } = props;
    const record = useRecordContext<IProfileUser>();


    return (
        <List
            filters={isSmall ? userFilters : undefined}
            sort={{ field: 'lastSeen', order: 'DESC' }}
            perPage={25}
        // aside={<UserListAside />}
        >
            {isXsmall ? (
                <MobileGrid />
            ) : (
                <Datagrid
                    optimized
                    rowClick="edit"
                    sx={{
                        '& .column-groups': {
                            md: { display: 'none' },
                            lg: { display: 'table-cell' },
                        },
                    }}
                >
                    <NumberField source='id' label="resources.profile.users.fields.id" />

                    <ReferenceField source="profileRoleId" reference="profile/roles/core" link={false} label="resources.profile.users.fields.role">
                        <TextField source="name" />
                    </ReferenceField>

                    <Link to="" label="resources.profile.users.fields.username">
                        <TextField source='username' />
                    </Link>

                    <TextField source='email' label="resources.profile.users.fields.email" />
                    <TextField source='phone' label="resources.profile.users.fields.phone" />
                    <TextField source='firstName' label="resources.profile.users.fields.firstName" />
                    <TextField source='lastName' label="resources.profile.users.fields.lastName" />
                    <TextField source='firstSeen' label="resources.profile.users.fields.firstSeen" />
                    <TextField source='lastSeen' label="resources.profile.users.fields.lastSeen" />
                    


                    <NumberField source='userSumBeginUsdt' label="resources.profile.users.fields.userSumBeginUsdt" />
                    <NumberField source='userSumLastUsdt' label="resources.profile.users.fields.userSumLastUsdt" />
                    <NumberField source='userSumChangeUsdt' label="resources.profile.users.fields.userSumChangeUsdt" />
                    <NumberField source='userSumChangePcnt' label="resources.profile.users.fields.userSumChangePcnt" />
                    <NumberField source='userSumWithdrawUsdt' label="resources.profile.users.fields.userSumWithdrawUsdt" />
                    <NumberField source='userHistoryWithdrawUsdt' label="resources.profile.users.fields.userHistoryWithdrawUsdt" />

                    <TextField source='lang' label="resources.profile.users.fields.lang" />
                    <TextField source='avatarPath' label="resources.profile.users.fields.avatarPath" />

                    <BooleanField source='active' label="resources.profile.users.fields.active" />
                    <BooleanField source='deleted' label="resources.profile.users.fields.deleted" />

                    <EditButton />
                    <DeleteButton />

                </Datagrid>
            )}
        </List>
    );
};

export default UserList;
