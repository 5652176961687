import RolesIcon from '@mui/icons-material/People';

import RolesList from './RolesList';
import RolesCreate from './RolesCreate';
import RolesEdit from './RolesEdit';

const resource = {
    list: RolesList,
    create: RolesCreate,
    edit: RolesEdit,
    icon: RolesIcon,
};

export default resource;
