import * as React from 'react';

import {
    DateInput,
    Edit,
    NullableBooleanInput,
    TextInput,
    PasswordInput,
    SimpleForm,
    useTranslate,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    NumberInput,
} from 'react-admin';

import { Grid, Box, Typography } from '@mui/material';


import { validateForm } from './WithdrawCreate';



// const UserTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;


const WithdrawEdit = () => (

    <Edit
    // title={<UserTitle />} 
    // aside={<Aside />}
    >
        <SimpleForm
            sx={{ maxWidth: 500 }}
            validate={validateForm}
        >

            <SectionTitle label="resources.withdraws.withdraws.fieldGroups.identity" />

            <TextInput source='status' label="resources.withdraws.withdraws.fields.status" />
                        
            <TextInput source='invoiceCoin' label="resources.withdraws.withdraws.fields.invoiceCoin" />
            <NumberInput source='invoiceCost' label="resources.withdraws.withdraws.fields.invoiceCost" />
            <TextInput source='walletAddress' label="resources.withdraws.withdraws.fields.walletAddress" />
            


        </SimpleForm>
    </Edit>

);



const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default WithdrawEdit;
