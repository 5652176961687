import * as React from 'react';

import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    EditButton,
    FunctionField,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    TextField
} from 'react-admin';

import { useMediaQuery, Theme } from '@mui/material';



const filters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="last_seen_gte" />,
    <NullableBooleanInput source="has_ordered" />,

];


const tariffPlanField = (record: any) => `#id${record.id} #${record.name}`;



const DaysList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            filters={filters}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={25}
        >

            <Datagrid
                optimized
                // rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                <TextField source="id" label="id" />

                <NumberField source='billingAccountId' label="resources.billing.days.fields.billingAccountId" />

                <DateField source="date" showTime label="resources.billing.days.fields.date" />

                <NumberField source='accountDayNumber' label="resources.billing.days.fields.accountDayNumber" />
                <TextField source='daySumBeginUsdt' label="resources.billing.days.fields.daySumBeginUsdt"/>
                <TextField source='daySumLastUsdt' label="resources.billing.days.fields.daySumLastUsdt"/>
                <TextField source='daySumChangeUsdt' label="resources.billing.days.fields.daySumChangeUsdt"/>
                <TextField source='daySumChangePcnt' label="resources.billing.days.fields.daySumChangePcnt"/>

                
                <DateField source="createdAt" showTime label="resources.billing.days.fields.createdAt" />
                <DateField source="updatedAt" showTime label="resources.billing.days.fields.updatedAt" />

                {/* <EditButton />
                <DeleteButton /> */}

            </Datagrid>

        </List>
    );
};

export default DaysList;
