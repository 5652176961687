import { TranslationMessages } from "react-admin";
import englishMessages from "ra-language-english";

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
  pos: {
    search: "Search",
    configuration: "Configuration",
    language: "Language",
    theme: {
      name: "Theme",
      light: "Light",
      dark: "Dark",
    },
    dashboard: {
      month_history: "History",
      new_customers: "New Customers",
      all_customers: "See all customers",
      welcome: {
        title: "",
        subtitle: "",
        ra_button: "",
        demo_button: "",
      },
    },
    menu: {
      profile: "Profile",
      tariff: "Tariff",
      marketdata: "Market Data",
      billing: "Billing",
      faq: "FAQ",
      helppage: "Help Page",
      mainpage: "Main Page",
      news: "News",

      trading: "Trading",
      catalog: "Catalog",
      contest: "Contest",
    },
  },

  resources: {
    profile: {
      role: {
        name: "Roles",
        fields: {
          id: "Id",
          name: "Name",
          description: "Description",
          administrator: "Administrator",
          moderator: "Moderator",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {
          administrator: "Administrator",
          moderator: "Moderator",
        },
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Role",
        },
        errors: {},
      },
      users: {
        name: "Users",
        fields: {
          id: "Id",
          username: "username",
          password: "Password",
          confirm_password: "Confirm password",
          email: "E-mail",
          avatar: "Avatar",
          firstName: "First Name",
          lastName: "Last Name",
          role: "Role",
          firstSeen: "First Seen",
          lastSeen: "Last Seen",
          administrator: "Administrator",
          moderator: "Moderator",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {
          last_visited: "Last visited",
          today: "Today",
          this_week: "This week",
          last_week: "Last week",
          this_month: "This month",
          last_month: "Last month",
          earlier: "Earlier",
          has_ordered: "Has ordered",
          has_newsletter: "Has newsletter",
          group: "Segment",
        },
        fieldGroups: {
          identity: "Identity",
          role: "Role",
          history: "History",
          password: "Password",
          change_password: "Change Password",
          status: "Status",
        },
        page: {
          delete: "Delete User",
        },
        errors: {
          password_mismatch:
            "The password confirmation is not the same as the password.",
        },
      },
    },

    tariff: {
      categories: {
        name: "Categories",
        fields: {
          id: "Id",
          name: "Name",
          description: "Description",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Category",
        },
        errors: {},
      },
      plans: {
        name: "Plan's",
        fields: {
          id: "Id",
          name: "Name",
          description: "Description",
          category: "Category",
          costProcent: "Cost of tariff in procent of start balance",
          fixedCostUsdt: "Fixed cost in USDT",
          startBalance: "Start balance",
          leverage: "Leverage",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Tariff",
        },
        errors: {},
      },
      stages: {
        name: "Stage's",
        fields: {
          id: "Id",
          name: "Name",
          plan: "Tariff",
          minProfitPerStageProcent: "Min profit per stage, %",
          maxLossPerStageProcent: "Max loss per stage, %",
          tradingDaysCount: "Number of trading days",
          minProfitPerDayProcent: "Min profit per trading day, %",
          maxLossPerDayProcent: "Max loss per trading day, %",
          ordering: "Order for display",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Stage",
        },
        errors: {},
      },
    },

    marketdata: {
      coin: {
        name: "Coins",
        fields: {
          id: "Id",
          name: "Name",
          type: "type",
          symbol: "Symbol",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Coin",
        },
        errors: {},
      },

      rateLast: {
        name: "Quotes Last",
        fields: {
          id: "Id",
          coin: "Coin",
          costByUsd: "Cost by USD",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Rate Last",
        },
        errors: {},
      },

      rate: {
        name: "Quotes",
        fields: {
          id: "Id",
          coin: "Coin",
          costByUsd: "Cost by USD",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Rate Last",
        },
        errors: {},
      },
    },

    billing: {
      account: {
        name: "Accounts",
        fields: {
          id: "Id",
          realCostUsdt: "Real cost bu USDT",
          startBalance: "Start balance",
          leverage: "Leverage",
          active: "Active",
          deleted: "Deteled",
          tariff: "Tariff",
          user: "User",

          tariffPlanId: "tariffPlanId",
          currentTariffStageId: "current Tariff Stage Id",
          currentTariffStageNumber: "current Tariff Stage Number",
          tariffStagesCount: "tariff Stages Count",
          currentBillingStageId: "current Billing Stage Id",
          currentDayId: "current Day Id",
          currentDayNumber: "current Day Number",
          tradingDaysCount: "trading Days Count",
          totalLast: "total Last",
          totalAccountBegin: "total Account Begin",
          totalAccountChange: "totalAccountChange",
          totalAccountPcnt: "totalAccountPcnt",
          selected: "selected",
          activeManual: "activeManual",
          activeForever: "activeForever",
          beginAt: "beginAt",
          endAt: "endAt",
          stopAt: "stopAt",
          stopReason: "stopReason",
          complete: "complete",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Account",
        },
        errors: {},
      },
      stages: {
        name: "Stages",
        fields: {
          id: "Id",
          billingAccountId: "billing Account Id",
          tariffStageId: "tariff Stage Id",
          currentDayId: "current Day Id",
          totalLast: "total Last",
          totalStageBegin: "total Stage Begin",
          totalStageChange: "total Stage Change",
          totalStagePcnt: "total Stage Pcnt",
          minProfitPerStageProcentFlag: "min Profit Per Stage Procent Flag",
          maxLossPerStageProcentFlag: "max Loss Per Stage Procent Flag",
          stopAt: "stop At",
          stopReason: "stop Reason",
          complete: "complete",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Account",
        },
        errors: {},
      },
      days: {
        name: "Days",
        fields: {
          id: "Id",
          billingStageId: "billing Stage Id",
          date: "date",
          tradingDayNumber: "trading Day Number",
          totalLast: "total Last",
          usdtValue24hOpen: "usdt Value 24h Open",
          usdtValue24hChange: "usdt Value 24h Change",
          usdtValue24hPcnt: "usdt Value 24h Pcnt",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Account",
        },
        errors: {},
      },
      wallet: {
        name: "Wallets",
        fields: {
          id: "Id",
          account: "Account",
          tariff: "Tariff",
          price: "Price",
          comment: "Comment",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {},
        errors: {},
      },
      payment: {
        name: "Payments",
        fields: {
          id: "Id",
          account: "Account",
          tariff: "Tariff",
          price: "Price",
          comment: "Comment",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {},
        errors: {},
      },
      contest: {
        name: "Contests",
        fields: {
          id: "Id",
          initialBalance: "InitialBalance",
          currentBalance: "CurrentBalance",
          finalBalance: "FinalBalance",
          minProfitPerStageProcent: "MinProfitPerStageProcent",
          maxLossPerStageProcent: "MaxLossPerStageProcent",
          condition: "Condition",
          currentProfit: "CurrentProfit",
          currentProfitProcent: "CurrentProfitProcent",
          endDayProfit: "EndDayProfit",
          endDayProfitProcent: "EndDayProfitProcent",
          endStageProfit: "EndStageProfit",
          endStageProfitPercent: "EndStageProfitPercent",
          tariffStageId: "TariffStageId",
          billingAccountId: "BillingAccountId",

          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {},
        errors: {},
      },
    },

    faq: {
      categories: {
        name: "Categories",
        fields: {
          id: "Id",
          name: "Name",
          description: "Description",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Category",
        },
        errors: {},
      },
      entry: {
        name: "Entries",
        fields: {
          id: "Id",
          question: "Question",
          answer: "Answer",
          active: "Active",
          deleted: "Deteled",
          categoryName: "Category Name",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Entry",
        },
        errors: {},
      },
    },

    helppage: {
      category: {
        name: "Categories",
        fields: {
          id: "Id",
          name: "Name",
          description: "Description",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Category",
        },
        errors: {},
      },
      entry: {
        name: "Entries",
        fields: {
          id: "Id",
          title: "Name",
          content: "Description",
          active: "Active",
          deleted: "Deteled",
          helppageCategoriesId: "CategoriesId",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Entry",
        },
        errors: {},
      },
    },

    mainpage: {
      entry: {
        name: "Entries",
        fields: {
          id: "Id",
          title: "Name",
          content: "Description",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Entry",
        },
        errors: {},
      },
    },

    news: {
      category: {
        name: "Categories",
        fields: {
          id: "Id",
          name: "Name",
          description: "Description",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Category",
        },
        errors: {},
      },
      posts: {
        name: "Posts",
        fields: {
          id: "Id",
          category: "Category",
          title: "Name",
          preview: "Preview",
          content: "Content",
          beginAt: "Begin ",
          endAt: "End",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Entry",
        },
        errors: {},
      },
    },

    trading: {
      executions: {
        name: "Executions",
        fields: {
          id: "Id",
          name: "Name",
          instrument: "Instrument",
          price: "Price",
          time: "Time",
          qty: "Qty",
          side: "Side",
          orderId: "orderId",
          isClose: "isClose",
          positionId: "positionId",
          commission: "commission",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Category",
        },
        errors: {},
      },
      orders: {
        name: "Orders",
        fields: {
          id: "Id",
          marketdataPairId: "PairId",
          instrument: "Instrument",
          qty: "Qty",
          side: "side",
          type: "type",
          status: "status",
          filledQty: "filledQty",
          avgPrice: "avgPrice",
          limitPrice: "limitPrice",
          stopPrice: "stopPrice",
          trailingStopPips: "trailingStopPips",
          isTrailingStop: "isTrailingStop",
          isGuaranteedStop: "isGuaranteedStop",
          parentId: "parentId",
          parentType: "parentType",
          duration: "duration",
          lastModified: "lastModified",
          commission: "commission",
          messageType: "messageType",
          messageText: "messageText",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Category",
        },
        errors: {},
      },
      positions: {
        name: "Positions",
        fields: {
          id: "Id",
          unrealizedPl: "unrealizedPl",
          description: "Description",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Category",
        },
        errors: {},
      },
    },
  },
};

export default customEnglishMessages;
