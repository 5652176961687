import * as React from 'react';

import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    EditButton,
    FunctionField,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectInput,
    TextField
} from 'react-admin';

import { useMediaQuery, Theme } from '@mui/material';



const filters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="last_seen_gte" />,
    <NullableBooleanInput source="has_ordered" />,

];


const tariffPlanField = (record: any) => `#id${record.id} #${record.name}`;



const AccountList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            filters={filters}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={25}
        >

            <Datagrid
                optimized
                // rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                <TextField source="id" label="id" />

                
                <ReferenceField source="profileUserId" reference="profile/users/core" label="resources.billing.account.fields.profileUserId">
                    <NumberField source='id' />
                </ReferenceField>

                <ReferenceField source="tariffPlanId" reference="tariff-plans/core" label="resources.billing.account.fields.tariffPlanId">
                    <NumberField source="id" />
                </ReferenceField>

                <TextField source='accountSumBeginUsdt' label="resources.billing.account.fields.accountSumBeginUsdt" />
                <TextField source='accountSumLastUsdt' label="resources.billing.account.fields.accountSumLastUsdt" />
                <TextField source='accountSumChangeUsdt' label="resources.billing.account.fields.accountSumChangeUsdt" />
                <NumberField source='accountSumChangePcnt' label="resources.billing.account.fields.accountSumChangePcnt" />
                <TextField source='accountSumWithdrawUsdt' label="resources.billing.account.fields.accountSumWithdrawUsdt" />

                <DateField source="beginAt" showTime label="resources.billing.account.fields.beginAt" />
                <DateField source="endAt" showTime label="resources.billing.account.fields.endAt" />

                <NumberField source='accountDayNumber' label="resources.billing.account.fields.accountDayNumber" />
                <NumberField source='accountDaysCount' label="resources.billing.account.fields.accountDaysCount" />

                <TextField source='stage1SumBeginUsdt' label="resources.billing.account.fields.stage1SumBeginUsdt" />
                <TextField source='stage1SumLastUsdt' label="resources.billing.account.fields.stage1SumLastUsdt" />
                <TextField source='stage1SumChangeUsdt' label="resources.billing.account.fields.stage1SumChangeUsdt" />

                <NumberField source='stage1SumChangePcnt' label="resources.billing.account.fields.stage1SumChangePcnt" />

                <BooleanField source='stage1MinProfitPcntFlag' label="resources.billing.account.fields.stage1MinProfitPcntFlag" />
                <BooleanField source='stage1MaxLossPcntFlag' label="resources.billing.account.fields.stage1MaxLossPcntFlag" />

                <NumberField source='stage1DayNumber' label="resources.billing.account.fields.stage1DayNumber" />
                <NumberField source='stage1CompletePcnt' label="resources.billing.account.fields.stage1CompletePcnt" />

                <TextField source='stage2SumBeginUsdt' label="resources.billing.account.fields.stage2SumBeginUsdt" />
                <TextField source='stage2SumLastUsdt' label="resources.billing.account.fields.stage2SumLastUsdt" />
                <TextField source='stage2SumChangeUsdt' label="resources.billing.account.fields.stage2SumChangeUsdt" />

                <NumberField source='stage2SumChangePcnt' label="resources.billing.account.fields.stage2SumChangePcnt" />

                <BooleanField source='stage2MinProfitPcntFlag' label="resources.billing.account.fields.stage1MaxLossPcntFlag" />
                <BooleanField source='stage2MaxLossPcntFlag' label="resources.billing.account.fields.stage1MaxLossPcntFlag" />

                <NumberField source='stage2DayNumber' label="resources.billing.account.fields.stage2DayNumber" />
                <NumberField source='stage2CompletePcnt' label="resources.billing.account.fields.stage2CompletePcnt" />

                <TextField source='status' label="resources.billing.account.fields.status" />

                <DateField source="statusAt" showTime label="resources.billing.account.fields.statusAt" />

                <TextField source='statusMessage' label="resources.billing.account.fields.statusMessage" />
                <TextField source='invoiceCoin' label="resources.billing.account.fields.invoiceCoin" />

                <NumberField source='invoiceCost' label="resources.billing.account.fields.invoiceCost" />

                <BooleanField source='selected' label="resources.billing.account.fields.selected" />
                <BooleanField source='reported' label="resources.billing.account.fields.reported" />


                <DateField source="createdAt" showTime label="resources.billing.account.fields.createdAt" />
                <DateField source="updatedAt" showTime label="resources.billing.account.fields.updatedAt" />
                


                



                {/* <NumberField source='tariff_plans.realCostUsdt' label="resources.billing.account.fields.realCostUsdt" />
                <NumberField source='tariff_plans.startBalanceUsdt' label="resources.billing.account.fields.startBalance" />
                <NumberField source='tariff_plans.leverage' label="resources.billing.account.fields.leverage" />
                <NumberField source='tariff_plans.totalLast' label="resources.billing.account.fields.leverage" />
                <NumberField source='tariff_plans.totalAccountBegin' label="resources.billing.account.fields.leverage" />
                <NumberField source='tariff_plans.totalAccountChange' label="resources.billing.account.fields.leverage" />
                <NumberField source='tariff_plans.totalAccountPcnt' label="resources.billing.account.fields.leverage" /> */}

                {/* <BooleanField source="active" label="resources.billing.account.fields.active" /> */}
                

                <EditButton />
                <DeleteButton />

            </Datagrid>

        </List>
    );
};

export default AccountList;
