import EntryIcon from "@mui/icons-material/People";

import ContestList from "./ContestList";
import EntryCreate from "./EntryCreate";
import EntryEdit from "./EntryEdit";

const resource = {
  list: ContestList,
  create: EntryCreate,
  edit: EntryEdit,
  icon: EntryIcon,
};

export default resource;
