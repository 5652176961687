import * as React from 'react';

import {
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    EditButton,
    List,
    NullableBooleanInput,
    SearchInput,
    TextField
} from 'react-admin';

import { useMediaQuery, Theme } from '@mui/material';



const roleFilters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="last_seen_gte" />,
    <NullableBooleanInput source="has_ordered" />,
 
];

const RoleList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            filters={roleFilters}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={25}
        >
           
            <Datagrid
                optimized
                // rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                <TextField source="id" label="id" />
                <TextField source="name" label="resources.profile.roles.fields.name"/>
                <TextField source="description" label="resources.profile.roles.fields.description"/>
                <BooleanField source="administrator" label="resources.profile.roles.fields.administrator"/>
                <BooleanField source="moderator" label="resources.profile.roles.fields.moderator"/>
                <BooleanField source="active" label="resources.profile.roles.fields.active"/>
                <DateField source="createdAt" showTime label="resources.profile.roles.fields.createdAt"/>
                <DateField source="updatedAt" showTime label="resources.profile.roles.fields.updatedAt"/>

                <EditButton />
                <DeleteButton />

            </Datagrid>
          
        </List>
    );
};

export default RoleList;
