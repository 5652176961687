import * as React from 'react';

import {
    BooleanField, Datagrid, DateField, DateInput, List, NullableBooleanInput,
    NumberField, SearchInput, Link, useRecordContext, FieldProps, TextField,
    DeleteButton, EditButton, ReferenceField
} from 'react-admin';

import { useMediaQuery, Theme, SxProps, Typography } from '@mui/material';


import { IProfileUser } from './types';

interface Props extends FieldProps<IProfileUser> {
    size?: string;
    sx?: SxProps;
}



const userFilters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="lastSeen_gte" />,
    <NullableBooleanInput source="has_ordered" />,
    <NullableBooleanInput source="has_newsletter" defaultValue />,
];

const WithdrawList = (props: Props) => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const { size } = props;
    const record = useRecordContext<IProfileUser>();


    return (
        <List
            filters={isSmall ? userFilters : undefined}
            sort={{ field: 'lastSeen', order: 'DESC' }}
            perPage={25}
        // aside={<UserListAside />}
        >

            <Datagrid
                optimized
                rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                {/* <TextField source="name" label="resources.withdraws.withdraws.name"/> */}
                <NumberField source='id' label="resources.withdraws.withdraws.fields.id" />

                <NumberField source='profileUserId' label="resources.withdraws.withdraws.fields.profileUserId" />

                <TextField source='invoiceCoin' label="resources.withdraws.withdraws.fields.invoiceCoin" />

                <TextField source='invoiceCost' label="resources.withdraws.withdraws.fields.invoiceCost" />
                <TextField source='walletAddress' label="resources.withdraws.withdraws.fields.walletAddress" />
                <TextField source='status' label="resources.withdraws.withdraws.fields.status" />

                <DateField source='statusAt' label="resources.withdraws.withdraws.fields.statusAt" />
                <DateField source='createdAt' label="resources.withdraws.withdraws.fields.createdAt" />
                <DateField source='updatedAt' label="resources.withdraws.withdraws.fields.updatedAt" />

                <EditButton />
                <DeleteButton />

            </Datagrid>

        </List>
    );
};

export default WithdrawList;
