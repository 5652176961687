import * as React from 'react';

import {
    DateInput,
    Edit,
    NullableBooleanInput,
    TextInput,
    PasswordInput,
    SimpleForm,
    useTranslate,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    NumberInput,
} from 'react-admin';

import { Grid, Box, Typography } from '@mui/material';

import { validateForm } from './AccountCreate';



// const AccountTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;

const tariffPlanField = (record: any) => ` #${record.name}`;



const AccountEdit = () => (

    <Edit>
        <SimpleForm
            sx={{ maxWidth: 500 }}
            defaultValues={{
                active: true,
            }}
            validate={validateForm}
        >
            <Separator />
            <SectionTitle label="resources.billing.account.fieldGroups.main" />

            <TextInput source="invoiceCoin" label="resources.billing.account.fields.invoiceCoin"/>
            <NumberInput source="invoiceCost" label="resources.billing.account.fields.invoiceCost"/>

            {/* <ReferenceInput source="tariffPlanId" reference="tariff-plans/core">
                <SelectInput optionText={tariffPlanField} isRequired fullWidth />
            </ReferenceInput> */}

                <ReferenceInput source="tariffPlanId" reference="tariff-plans/core" label="resources.billing.account.fields.tariffPlanId">
                    <SelectInput optionText="id" isRequired fullWidth />
                </ReferenceInput>

            <NumberInput source="totalLast" label="resources.billing.account.fields.totalLast"/>
            <NumberInput source="totalAccountBegin" label="resources.billing.account.fields.totalAccountBegin"/>

            

            

            

            <TextInput source="status" label="resources.billing.account.fields.status"/>
            <TextInput source="statusMessage" label="resources.billing.account.fields.statusMessage"/>

            <TextInput source="beginAt" label="resources.billing.account.fields.beginAt"/>
            <TextInput source="endAt" label="resources.billing.account.fields.endAt"/>

            <BooleanInput source='reported' label="resources.billing.account.fields.reported" fullWidth />
            <BooleanInput source='selected' label="resources.billing.account.fields.selected" fullWidth />


            {/* <TextInput source='tariffPlanId' fullWidth />
                <TextInput source='currentTariffStageId' fullWidth />
                <TextInput source='currentTariffStageNumber' fullWidth />
                <TextInput source='tariffStagesCount' fullWidth />
                <TextInput source='currentBillingStageId' fullWidth />
                <TextInput source='currentDayId' fullWidth />
                <TextInput source='currentDayNumber' fullWidth />
                <TextInput source='tradingDaysCount' fullWidth />
                <TextInput source='totalLast' fullWidth />
                <TextInput source='totalAccountBegin' fullWidth />
                <TextInput source='totalAccountChange' fullWidth />
                <TextInput source='totalAccountPcnt' fullWidth />
                <BooleanInput source='selected' fullWidth />
                <BooleanInput source='activeManual' fullWidth />
                <BooleanInput source='activeForever' fullWidth />
                <TextInput source='beginAt' fullWidth />
                <TextInput source='endAt' fullWidth />
                <TextInput source='stopAt' fullWidth />
                <TextInput source='stopReason' fullWidth />
                <TextInput source='complete' fullWidth />
            <Separator />
            <SectionTitle label="resources.billing.account.fieldGroups.status" /> */}

            {/* <BooleanInput source="active" fullWidth /> */}

        </SimpleForm>

    </Edit>
);


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default AccountEdit;
