import * as React from 'react';

import {
    Create,
    DateInput,
    SimpleForm,
    TextInput,
    useTranslate,
    PasswordInput,
    email,
    ReferenceField,
    TextField,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    NumberField,
    NumberInput,
} from 'react-admin';

import { Box, Typography } from '@mui/material';
import { Password } from '@mui/icons-material';



export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.first_name) {
        errors.first_name = 'ra.validation.required';
    }
    if (!values.last_name) {
        errors.last_name = 'ra.validation.required';
    }
    if (!values.email) {
        errors.email = 'ra.validation.required';
    } else {
        const error = email()(values.email);
        if (error) {
            errors.email = error;
        }
    }
    if (values.password && values.password !== values.confirm_password) {
        errors.confirm_password =
            'resources.profile.user.errors.password_mismatch';
    }
    return errors;
};



const WithdrawCreate = () => (
    <Create>
        <SimpleForm
            sx={{ maxWidth: 500 }}
            // Here for the GQL provider
            defaultValues={{
                firstSeen: new Date(),
                lastSeen: new Date(),
                has_ordered: false,
                latest_purchase: new Date(),
                has_newsletter: false,
                groups: [],
                nb_commands: 0,
                total_spent: 0,
            }}
            validate={validateForm}
        >
            <SectionTitle label="resources.withdraws.withdraws.fieldGroups.identity" />

            <ReferenceInput source="profileUserId" reference="profile/users/core">
                <SelectInput optionText="id" isRequired fullWidth />
            </ReferenceInput>
            
            <TextInput source='status' label="resources.withdraws.withdraws.fields.status" />
            
            <TextInput source='invoiceCoin' label="resources.withdraws.withdraws.fields.invoiceCoin" />
            <NumberInput source='invoiceCost' label="resources.withdraws.withdraws.fields.invoiceCost" />
            <TextInput source='walletAddress' label="resources.withdraws.withdraws.fields.walletAddress" />

        



        </SimpleForm>
    </Create>
);


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default WithdrawCreate;
